//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Modal',
  data() {
    return {
      snils: '',
      snilsState: null,
    }
  },
  props: {
    title: String,
    saveText: String,
    cancelText: String,
  },
  emits: ['ok', 'cancel'],
}
